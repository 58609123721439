var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"clickoutside",rawName:"v-clickoutside",value:(_vm.hide),expression:"hide"}],class:[
    'ods-color-picker',
    _vm.colorDisabled ? 'is-disabled' : '',
    _vm.colorSize ? `ods-color-picker--${ _vm.colorSize }` : ''
  ],attrs:{"data-name":"OdsColorPicker"}},[(_vm.colorDisabled)?_c('div',{staticClass:"ods-color-picker__mask"}):_vm._e(),_c('div',{staticClass:"ods-color-picker__trigger",on:{"click":_vm.handleTrigger}},[_c('span',{staticClass:"ods-color-picker__color",class:{ 'is-alpha': _vm.showAlpha }},[_c('span',{staticClass:"ods-color-picker__color-inner",style:({
          backgroundColor: _vm.displayedColor
        })}),(!_vm.value && !_vm.showPanelColor)?_c('span',{staticClass:"ods-color-picker__empty ods-icon-close"}):_vm._e()]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.value || _vm.showPanelColor),expression:"value || showPanelColor"}],staticClass:"ods-color-picker__icon ods-icon-arrow-down"})]),_c('picker-dropdown',{ref:"dropdown",class:['ods-color-picker__panel', _vm.popperClass || ''],attrs:{"color":_vm.color,"show-alpha":_vm.showAlpha,"predefine":_vm.predefine},on:{"pick":_vm.confirmValue,"clear":_vm.clearValue},model:{value:(_vm.showPicker),callback:function ($$v) {_vm.showPicker=$$v},expression:"showPicker"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }