var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"clickoutside",rawName:"v-clickoutside",value:(_vm.handleExpand),expression:"handleExpand"}],ref:"main",class:[
  'ods-input-search',
  {
    [`ods-input-search--${_vm.size}`]: !!_vm.size,
    'ods-input-search--expandable': _vm.expandableSearch,
    'ods-input-search--is-expanded': _vm.isExpanded,
    'ods-input-search--is-clearable': _vm.clearable,
    'ods-input-search--is-line': _vm.outlined,
    'ods-input-search--is-disabled': _vm.disabled
  }
  ]},[_c('ods-input',_vm._g({ref:"input",attrs:{"placeholder":_vm.placeholder,"searchInput":"","clearable":_vm.clearable,"disabled":_vm.disabled},on:{"keyEnter":_vm.handleSearch},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}},_vm.listeners),[(_vm.outlined)?_c('span',{staticClass:"ods-input__icon ods-icon-search",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(!_vm.outlined)?_c('ods-button',{attrs:{"slot":"append","icon":_vm.buttonIcon,"disabled":_vm.disabled},on:{"click":_vm.handleSearch},slot:"append"}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }