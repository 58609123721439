var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ods-sub-header",class:[_vm.cssClass,
  {
    'ods-sub-header--stretch': _vm.stretch,
    'ods-sub-header--medium': _vm.medium,
    'ods-sub-header--large': _vm.large,
    'ods-sub-header--is-absolute': _vm.isAbsolute,
  }]},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }