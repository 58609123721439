var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"ods-message-fade"},on:{"after-leave":_vm.handleAfterLeave}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],class:[
      'ods-message',
      _vm.type && !_vm.iconClass ? `ods-message--${ _vm.type }` : '',
      _vm.center ? 'is-center' : '',
      _vm.showClose ? 'is-closable' : '',
      _vm.customClass
    ],style:(_vm.positionStyle),attrs:{"data-name":"OdsMessage","role":"alert"},on:{"mouseenter":_vm.clearTimer,"mouseleave":_vm.startTimer}},[(_vm.iconClass)?_c('i',{class:_vm.iconClass}):_c('i',{class:_vm.typeClass}),_vm._t("default",function(){return [(!_vm.dangerouslyUseHTMLString)?_c('p',{staticClass:"ods-message__content"},[_vm._v(_vm._s(_vm.message))]):_c('p',{staticClass:"ods-message__content",domProps:{"innerHTML":_vm._s(_vm.message)}})]}),(_vm.showClose)?_c('i',{staticClass:"ods-message__closeBtn ods-icon-close",on:{"click":_vm.close}}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }