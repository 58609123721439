var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"ods-main-nav__item",class:[
    {
    'ods-main-nav__item--collapsed': _vm.collapsed,
    'active': _vm.activeItem
    },
    _vm.customClass
  ]},[_c('div',{staticClass:"ods-main-nav__item-inner"},[_c('div',{staticClass:"ods-main-nav__item-link",class:{
        'ods-main-nav__item-link--dropdown': _vm.hasChildren,
        'ods-main-nav__item-link--active': _vm.isOpen,
        'ods-main-nav__item-link--disabled': _vm.menuItem.disabled
        },on:{"mouseenter":function($event){return _vm.tooltipOn($event)},"mouseleave":function($event){return _vm.tooltipOff()}}},[_c('ods-tooltip',{attrs:{"popper-class":"ods-main-nav__tooltip","content":_vm.menuItem.name,"placement":"right","enterable":false,"manual":true},model:{value:(_vm.showTooltip),callback:function ($$v) {_vm.showTooltip=$$v},expression:"showTooltip"}},[_c(_vm.linkTag(),_vm._b({tag:"component",on:{"click":_vm.subMenuToggle}},'component',_vm.linkTagProps,false),[(_vm.menuItem.icon)?_c('ods-icon',{staticClass:"ods-main-nav__icon",attrs:{"name":_vm.menuItem.icon,"size":"22"}}):_vm._e(),_c('span',{staticClass:"ods-main-nav__item-text"},[_vm._v(" "+_vm._s(_vm.menuItem.name))]),(_vm.hasChildren && !_vm.collapsed)?_c('ods-icon',{staticClass:"dropdown-icon",attrs:{"name":_vm.isOpen ? 'arrow-down arrow-down--rotated' : 'arrow-down',"size":"20"}}):_vm._e()],1)],1)],1),(_vm.hasChildren)?_c('ods-navigation-submenu',{ref:"OdsNavigationSubmenu",attrs:{"children":_vm.menuItem.children,"subMenuName":_vm.menuItem.name,"isOpen":_vm.isOpen,"collapsed":_vm.collapsed,"reversed":_vm.reversedSubMenu,"reverseDiff":_vm.reverseDiff,"isFirstLevelItem":_vm.isFirstLevelItem,"scrollHeight":_vm.scrollHeight ? _vm.scrollHeight : 'auto',"submenuMaxHeight":_vm.submenuMaxHeight ? _vm.submenuMaxHeight : null},on:{"checkScroll":_vm.checkScroll,"checkSubMenuDirection":_vm.checkSubMenuDirection,"checkTransitionEndClose":el => _vm.checkNeedScroll(el, false),"checkTransitionEndOpen":el => _vm.checkNeedScroll(el, true)}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }