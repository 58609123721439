var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ods-popover',{attrs:{"width":_vm.mobileWidth && _vm.isMobile ? _vm.mobileWidth : _vm.width,"placement":_vm.mobilePopoverPosition && _vm.isMobile ? _vm.mobilePopoverPosition : _vm.popoverPosition,"popperClass":_vm.popoverCustomClasses,"visibleArrow":false,"popper-options":{
    boundariesElement: _vm.boundariesElement
  },"offset":_vm.offSet},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('ods-scrollbar',{attrs:{"wrapClass":"ods-actions-menu__scroll-area"}},[_vm._t("default")],2),_c(_vm.buttonTag,{tag:"component",staticClass:"ods-actions-menu__button",class:{
      'ods-actions-menu__button--active': _vm.isOpen,
      'ods-actions-menu__button--text': _vm.text,
      'ods-actions-menu__button--img': _vm.img
    },attrs:{"slot":"reference","name":_vm.icon ? _vm.icon : null,"src":_vm.img ? _vm.img : null,"alt":_vm.img ? 'action menu avatar image' : null},slot:"reference"},[(_vm.text)?[_vm._v(_vm._s(_vm.text))]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }