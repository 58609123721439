var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ods-badge",class:{'is-line': _vm.isLine && _vm.type === 'primary'},attrs:{"data-name":"OdsBadge"}},[_vm._t("default"),_c('transition',{attrs:{"name":"ods-zoom-in-center"}},[_c('sup',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hidden && (_vm.content || _vm.content === 0 || _vm.isDot)),expression:"!hidden && (content || content === 0 || isDot)"}],staticClass:"ods-badge__content",class:[
        'ods-badge__content--' + _vm.type,
        {
          'is-fixed': _vm.$slots.default,
          'is-dot': _vm.isDot
        }
      ],domProps:{"textContent":_vm._s(_vm.content)}})])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }