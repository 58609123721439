var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.node.visible),expression:"node.visible"}],ref:"node",staticClass:"ods-tree-node",class:{
      'is-expanded': _vm.expanded,
      'is-current': _vm.tree.store.currentNode === _vm.node,
      'is-hidden': !_vm.node.visible,
      'is-focusable': !_vm.node.disabled,
      'is-checked': !_vm.node.disabled && _vm.node.checked,
      'is-children': _vm.node.parent.level !== 0,
      'is-parent': _vm.node.childNodes.length
    },attrs:{"role":"treeitem","tabindex":"-1","aria-expanded":_vm.expanded,"aria-disabled":_vm.node.disabled,"aria-checked":_vm.node.checked,"draggable":_vm.tree.draggable},on:{"click":function($event){$event.stopPropagation();return _vm.handleClick.apply(null, arguments)},"contextmenu":($event) => this.handleContextMenu($event),"dragstart":function($event){$event.stopPropagation();return _vm.handleDragStart.apply(null, arguments)},"dragover":function($event){$event.stopPropagation();return _vm.handleDragOver.apply(null, arguments)},"dragend":function($event){$event.stopPropagation();return _vm.handleDragEnd.apply(null, arguments)},"drop":function($event){$event.stopPropagation();return _vm.handleDrop.apply(null, arguments)}}},[_c('div',{class:[
      'ods-tree-node__content',
      _vm.tree.contentClass ? _vm.tree.contentClass(_vm.node) : ''
],style:({ 'padding-left': (_vm.node.level - 1) * _vm.tree.indent + 'px' })},[_c('span',{class:[
          { 'is-leaf': _vm.node.isLeaf, expanded: !_vm.node.isLeaf && _vm.expanded },
          'ods-tree-node__expand-icon',
          _vm.tree.iconClass ? _vm.tree.iconClass : 'ods-icon-arrow-down'
        ],on:{"click":function($event){$event.stopPropagation();return _vm.handleExpandIconClick.apply(null, arguments)}}}),(_vm.showCheckbox)?_c('ods-checkbox',{attrs:{"indeterminate":_vm.node.indeterminate,"disabled":!!_vm.node.disabled},on:{"change":_vm.handleCheckChange},nativeOn:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.node.checked),callback:function ($$v) {_vm.$set(_vm.node, "checked", $$v)},expression:"node.checked"}}):_vm._e(),(_vm.node.loading)?_c('span',{staticClass:"ods-tree-node__loading-icon ods-icon-loading"}):_vm._e(),_c('node-content',{attrs:{"node":_vm.node}})],1),_c('ods-collapse-transition',[(!_vm.renderAfterExpand || _vm.childNodeRendered)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.expanded),expression:"expanded"}],staticClass:"ods-tree-node__children",attrs:{"role":"group","aria-expanded":_vm.expanded}},_vm._l((_vm.node.childNodes),function(child){return _c('ods-tree-node',{key:_vm.getNodeKey(child),attrs:{"render-content":_vm.renderContent,"render-after-expand":_vm.renderAfterExpand,"show-checkbox":_vm.showCheckbox,"node":child},on:{"node-expand":_vm.handleChildNodeExpand}})}),1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }