import enLocale from '@/addons/locale/lang/en'

export default {
  ...enLocale,
  label: 'English',
  labelMobile: 'EN',
  serverError: 'Unexpected server error. Try again.',
  email: 'Email',
  password: 'Password',
  login: 'Login',
  edit: 'Edit',
  back: 'Back',
  cancel: 'Cancel',
  users: 'Users',
  name: 'Name',
  enabled: 'Enabled',
  editUser: 'Edit user',
  newUser: 'New user',
  externalAuthentication: 'External authentication',
  confirm: 'Confirm',
  deleteAlert: 'Are you sure you want to delete this item?',
  description: 'Description',
  code: 'Code',
  save: 'Save',
  delete: 'Delete',
  errors: {
    required: 'This field is required',
    email: 'Please input correct email address',
    url: 'Please input correct URL',
    phone: 'Please input a correct phone number'
  },
  apiError: 'Ops there was an error',
  username: 'Username',
  changePassword: 'Change password',
  calculationMethods: 'Calculation methods',
  calculationMethod: 'Calculation method',
  createCalculationMethod: 'Create calculation method',
  editCalculationMethod: 'Edit calculation method',
  calculationModule: 'Calculation module',
  type: 'Type',
  total: 'Total',
  actions: 'Actions',
  forgotPassword: 'Forgot your password?',
  backToLogin: 'Back to login',
  calculationResults: 'Calculation results',
  calculationPeriod: 'Calculation period',
  date: 'Date',
  result: 'Result',
  daily: 'Daily',
  monthly: 'Monthly',
  '#serviceLevel': '# Service level',
  export: 'Export',
  'sla#': 'SLA#',
  sla: 'SLA',
  data: 'Data',
  download: 'Download',
  overall: 'Overall',
  p1: 'P1',
  p2: 'P2',
  p3: 'P3',
  slaType: 'SLA type',
  p1Result: 'P1 result',
  p2Result: 'P2 result',
  p3Result: 'P3 result',
  showLastExecution: 'Show last execution',
  audit: 'Audit',
  schedule: 'Schedule',
  frecuency: 'Frecuency',
  lastExecution: 'Last execution',
  nextExecution: 'Next execution',
  active: 'Active',
  dataEntry: 'Data entry',
  businessDate: 'Business date',
  dataType: 'Data type',
  businessStartDate: 'Business start date',
  businessEndDate: 'Business end date',
  value: 'Value',
  comments: 'Comments',
  OnDemandExecution: 'On-demand Execution',
  slaCalculationMethod: 'SLA Calculation Method',
  executionDate: 'Execution date',
  execute: 'Execute',
  slaName: 'SLA name',
  user: 'User',
  status: 'Status',
  slaDate: 'SLA date',
  startDateTime: 'Start date time',
  endDateTime: 'End date time',
  p1Limit: 'P1 Limit',
  p2Limit: 'P2 Limit',
  p3Limit: 'P3 Limit',
  p1Percent: 'P1 Percent',
  p2Percent: 'P2 Percent',
  p3Percent: 'P3 Percent',
  editCalculationModule: 'Edit calculation module',
  createCalculationModule: 'Create calculation module',
  editDataEntry: 'Edit data entry',
  createDataEntry: 'Create data entry',
  editSchedule: 'Edit schedule',
  createSchedule: 'Create schedule',
  monitoringExecution: 'Monitoring execution',
  calculationMethodSaved: 'Calculation method saved',
  calculationMethodDeleted: 'Calculation method deleted',
  dataEntrySaved: 'Data entry saved',
  dataEntryDeleted: 'Data entry deleted',
  scheduleSaved: 'Schedule saved',
  scheduleDeleted: 'Schedule deleted',
  details: 'Details',
  onDemandExecutionCreated: 'On demand execution created',
  onDemandExecutionsCreated: 'On demand executions created',
  plannedExecutionTime: 'Planned execution time',
  objetives: 'Objectives',
  createObjetive: 'Create objective',
  editObjetive: 'Edit objective',
  noObjetives: 'This Calculation method does not have objectives',
  target: 'Target',
  targetUOM: 'Target UOM',
  compare: 'Compare',
  numberOfTimes: 'Number of times',
  evaluationTime: 'Evaluation time',
  evaluationTimeUM: 'Evaluation time UOM',
  consecutiveTimes: 'Consecutive times',
  consecutiveTimesUM: 'Consecutive times UOM',
  zone: 'Zone',
  overallArchieved: 'Overall achieved',
  overallMetersAffected: 'Overall meters affected',
  p1Achieved: 'P1 Achieved',
  p1MetersAffected: 'P1 Meters Affected',
  p2Achieved: 'P2 Achieved',
  p2MetersAffected: 'P2 Meters Affected',
  p3Achieved: 'P3 Achieved',
  p3MetersAffected: 'P3 Meters Affected',
  startDate: 'Start date',
  endDate: 'End date',
  overallTotalAffected: 'Overall total meters affected',
  p1Affected: 'P1 meters affected',
  p2Affected: 'P2 meters affected',
  p3Affected: 'P3 meters affected',
  invalidDates: 'Invalid dates',
  slaSuccecion: 'SLA Success',
  success: 'Success',
  notSuccess: 'Not Success',
  mettersAffected: 'Meters affected',
  penalties: 'Penalties',
  dashboard: 'Dashboard',
  selectSLA: 'Please select an SLA',
  slaEvolution: 'SLA evolution',
  userDeleted: 'User deleted',
  lastName: 'Lastname',
  userSaved: 'User saved',
  createUser: 'Create user',
  showCurrentMonthOnly: 'Show current month only',
  reports: 'Reports',
  generateReport: 'Generate report',
  noReportData: 'No records found on these dates',
  limitTime: 'Limit time',
  dailyCalculationResults: 'Daily Calculation results',
  monthlyCalculationResults: 'Monthly Calculation results',
  errorCalcModuleHasDoneExecution:
    'Cannot delete this calculation method because has done executions',
  deleteCalculationMethodConfirmation:
    'This calculation method has done executions, are you sure you want to delete it? All data will be lost',
  auditConfirmationText: 'Are you sure you want to audit this SLA? A new execution will be created.',
  nonCompliant: 'Failed',
  nonCompliantConfirmationText: 'Are you sure you want to list all data that does not meet the conditions of this SLA?',
  calculateMonth: "Calculate month",
  database: "Database",
  OnDemandExtraction: "On-demand Extraction",
  creationDate: "Creation date",
  extractionStartDate: "Extraction start date",
  extractionEndDate: "Extraction end date",
  errorMessage: "Error message",
  createExtractionConfirmationText: "Are you sure you want to continue? All the data for the requested period will be deleted and uploaded again",
  onDemandExtractionCreated: "On demand extraction created",
  dataExtraction: "Data extraction",
  noMoreThanTwoMothRangeAllowed: "No more than two months of range are allowed",
  "The process will begin in a few seconds.": "The process will begin in a few seconds.",
  calculationMethodNameAlreadyTaken: "Calculation method name already taken"
}
