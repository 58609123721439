var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"ods-radio",class:[
    _vm.border && _vm.radioSize ? 'ods-radio--' + _vm.radioSize : '',
    { 'is-disabled': _vm.isDisabled },
    { 'is-focus': _vm.focus },
    { 'is-bordered': _vm.border },
    { 'is-checked': _vm.model === _vm.label }
  ],attrs:{"for":_vm.label,"data-name":"OdsRadio","role":"radio","aria-checked":_vm.model === _vm.label,"aria-disabled":_vm.isDisabled,"tabindex":_vm.tabIndex},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;$event.stopPropagation();$event.preventDefault();_vm.model = _vm.isDisabled ? _vm.model : _vm.label}}},[_c('span',{staticClass:"ods-radio__input",class:{
      'is-disabled': _vm.isDisabled,
      'is-checked': _vm.model === _vm.label
    }},[_c('span',{staticClass:"ods-radio__inner"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],ref:"radio",staticClass:"ods-radio__original",attrs:{"id":_vm.label,"type":"radio","aria-hidden":"true","name":_vm.name,"disabled":_vm.isDisabled,"tabindex":"-1"},domProps:{"value":_vm.label,"checked":_vm._q(_vm.model,_vm.label)},on:{"focus":function($event){_vm.focus = true},"blur":function($event){_vm.focus = false},"change":[function($event){_vm.model=_vm.label},_vm.handleChange]}})]),_c('span',{staticClass:"ods-radio__label",on:{"keydown":function($event){$event.stopPropagation();}}},[_vm._t("default"),(!_vm.$slots.default)?[_vm._v(_vm._s(_vm.label))]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }