import esLocale from '@/addons/locale/lang/es'

export default {
  ...esLocale,
  label: 'Español',
  labelMobile: 'ES',
  serverError:
    'Ha ocurrido un error inesperado en el servidor. Inténtalo de nuevo.',
  email: 'Email',
  password: 'Contraseña',
  login: 'Entrar',
  edit: 'Editar',
  back: 'Regresar',
  cancel: 'Cancelar',
  users: 'Usuarios',
  name: 'Nombre',
  enabled: 'Activo',
  editUser: 'Editar usuario',
  newUser: 'Crear usuario',
  externalAuthentication: 'Autenticación externa',
  confirm: 'Confirmar',
  deleteAlert: '¿Estás seguro que deseas borrar este elemento?',
  code: 'Código',
  description: 'Descripción',
  save: 'Salvar',
  delete: 'Eliminar',
  errors: {
    required: 'Este campo es requerido',
    email: 'Por favor introduzca un email correcto',
    url: 'Por favor introduzca un URL correcta',
    phone: 'Por favor introduzca un número de teléfono correcto'
  },
  apiError: 'Ops se produjo un error',
  username: 'Nombre de usuario',
  changePassword: 'Cambiar contraseña',
  calculationMethods: 'Métodos de cálculo',
  calculationMethod: 'Método de cálculo',
  createCalculationMethod: 'Crear método de cálculo',
  editCalculationMethod: 'Editar método de cálculo',
  calculationModule: 'Módulo de cálculo',
  type: 'Tipo',
  total: 'Total',
  actions: 'Acciones',
  forgotPassword: 'Olvidó su contraseña?',
  backToLogin: 'Regresar a autenticarce',
  calculationResults: 'Resultado de los cálculos',
  calculationPeriod: 'Período de cálculo',
  date: 'Fecha',
  result: 'Resultado',
  daily: 'Diario',
  monthly: 'Mensual',
  '#serviceLevel': '# Nivel de servicio',
  export: 'Exportar',
  'sla#': 'SLA#',
  sla: 'SLA',
  data: 'Datos',
  download: 'Descargar',
  overall: 'General',
  p1: 'P1',
  p2: 'P2',
  p3: 'P3',
  slaType: 'Tipo de SLA',
  p1Result: 'Resultado de P1',
  p2Result: 'Resultado de P2',
  p3Result: 'Resultado de P3',
  showLastExecution: 'Mostrar última ejecución',
  audit: 'Examinar',
  schedule: 'Calendario',
  frecuency: 'Frecuencia',
  lastExecution: 'Última ejecución',
  nextExecution: 'Siguiente ejecución',
  active: 'Activo',
  dataEntry: 'Entrada de datos',
  businessDate: 'Fecha comercial',
  dataType: 'Tipo de dato',
  businessStartDate: 'Fecha comercial inicial',
  businessEndDate: 'Fecha comercial final',
  value: 'Valor',
  comments: 'Commentarios',
  OnDemandExecution: 'Ejecución bajo demanda',
  slaCalculationMethod: 'Método de Cálculo del SLA',
  executionDate: 'Fecha de ejecución',
  execute: 'Ejecutar',
  slaName: 'Nombre del SLA',
  user: 'Usuario',
  status: 'Estado',
  slaDate: 'Fecha del SLA',
  startDateTime: 'Fecha de inicio',
  endDateTime: 'Fecha de fin',
  p1Limit: 'Límite de P1',
  p2Limit: 'Límite de P2',
  p3Limit: 'Límite de P3',
  p1Percent: 'Porciento de P1',
  p2Percent: 'Porciento de P2',
  p3Percent: 'Porciento de P3',
  editCalculationModule: 'Editar módulo de cálculo',
  createCalculationModule: 'Crear módulo de cálculo',
  editDataEntry: 'Editar entrada de dato',
  createDataEntry: 'Crear entrada de dato',
  editSchedule: 'Editar calendario',
  createSchedule: 'Crear calendario',
  monitoringExecution: 'Seguimiento de ejecuciones',
  calculationMethodSaved: 'Método de cálculo guardado',
  calculationMethodDeleted: 'Método de cálculo eliminado',
  dataEntrySaved: 'Entrada de datos guardada',
  dataEntryDeleted: 'Entrada de datos eliminada',
  scheduleSaved: 'Calendario guardado',
  scheduleDeleted: 'Calendario eliminado',
  details: 'Detalles',
  onDemandExecutionCreated: 'Ejecución bajo demanda creada',
  onDemandExecutionsCreated: 'Ejecuciones bajo demanda creadas',
  plannedExecutionTime: 'Tiempo de ejecución planeado',
  objetives: 'Objetivos',
  createObjetive: 'Crear objetivo',
  editObjetive: 'Editar objetivo',
  noObjetives: 'Esta Método de cálculo no tiene objetivos',
  target: 'Meta',
  targetUOM: 'UOM Meta',
  compare: 'Comparar',
  numberOfTimes: 'Número de veces',
  evaluationTime: 'Tiempo de evaluación',
  evaluationTimeUM: 'UOM Tiempo de evaluación',
  consecutiveTimes: 'Veces consecutivas',
  consecutiveTimesUM: 'Veces consecutivas UOM',
  zone: 'Zona',
  overallArchieved: 'Overall Logrado',
  overallMetersAffected: 'Overall Medidores involucrados',
  p1Achieved: 'P1 Logrado',
  p1MetersAffected: 'P1 Medidores involucrados',
  p2Achieved: 'P2 Logrado',
  p2MetersAffected: 'P2 Medidores involucrados',
  p3Achieved: 'P3 Logrado',
  p3MetersAffected: 'P3 Medidores involucrados',
  startDate: 'Fecha desde',
  endDate: 'Fecha hasta',
  overallTotalAffected: 'Total de medidores afectados en general',
  p1Affected: 'Medidores p1 afectados',
  p2Affected: 'Medidores p2 afectados',
  p3Affected: 'Medidores p3 afectados',
  invalidDates: 'Fechas no válidas',
  slaSuccecion: 'Sucesión del SLA',
  success: 'Success',
  notSuccess: 'Not Success',
  mettersAffected: 'Medidores afectados',
  penalties: 'Penaltis',
  dashboard: 'Dashboard',
  selectSLA: 'Por favor seleccione un SLA',
  slaEvolution: 'Evolución del SLA',
  userDeleted: 'Usuario eliminado',
  lastName: 'Apellidos',
  userSaved: 'Usuario guardado',
  createUser: 'Crear usuario',
  showCurrentMonthOnly: 'Mostrar solo el mes actual',
  reports: 'Reportes',
  generateReport: 'Generar reporte',
  noReportData: 'No se encontraron registros en estas fechas',
  limitTime: 'Tiempo límite',
  dailyCalculationResults: 'Resultado diario de los cálculos',
  monthlyCalculationResults: 'Resultado mensual de los cálculos',
  errorCalcModuleHasDoneExecution:
    'No se puede eliminar este método de cáculo porque tiene ejecuciones hechas',
  deleteCalculationMethodConfirmation:
    'Este método de cálculo tiene ejecuciones hechas, ¿esta seguro de que desea eliminarlo? Todos los datos se perderán',
  auditConfirmationText: 'Está seguro de que desea auditar ese SLA? una ejecución se creará.',
  nonCompliant: 'Datos no conformes',
  nonCompliantConfirmationText: '¿Está seguro que desea enumerar todos los datos que no cumplen con las condiciones de este SLA?',
  calculateMonth: "Calcular mes",
  database: "Base de datos",
  OnDemandExtraction: "Extracción bajo demanda",
  creationDate: "Fecha de creación",
  extractionStartDate: "Fecha de inicio de la extracción",
  extractionEndDate: "Fecha de final de la extracción",
  errorMessage: "Mensaje de error",
  createExtractionConfirmationText: "¿Está seguro de que desea continuar? Todos los datos del período solicitado se eliminarán y se cargarán nuevamente",
  onDemandExtractionCreated: "Extracción bajo demanda creada",
  dataExtraction: "Extracción de datos",
  noMoreThanOneMothRangeAllowed: "El período debe ser inferior a dos meses",
  "The process will begin in a few seconds.": "El proceso comensará en unos segundos",
  calculationMethodNameAlreadyTaken: "Este nombre de módulo de cálculo ya está en uso"
}
