var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[(_vm.$route.params.token)?_c('ods-main-header',{attrs:{"headerLogoText":"Onesait e-Services","logoUrl":"/"}}):_vm._e(),_c('div',{staticStyle:{"display":"grid","justify-content":"center"}},[_c('ods-module',{staticStyle:{"width":"100%","margin-top":"30px"},attrs:{"header":_vm.$route.params.token ? _vm.$t('recoverPassword') : _vm.$t('changePassword')}},[_c('ods-form',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"form",attrs:{"model":_vm.form}},[_c('ods-row',{attrs:{"gutter":20}},[_c('ods-col',{attrs:{"md":24}},[_c('ods-form-item',{attrs:{"label":_vm.$route.params.token
                  ? _vm.$t('newPassword')
                  : _vm.$t('currentPassword'),"prop":"password","rules":[_vm.errors.required]}},[_c('ods-input',{attrs:{"type":"password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1)],1),_c('ods-col',{attrs:{"md":24}},[_c('ods-form-item',{attrs:{"label":_vm.$route.params.token
                  ? _vm.$t('repeatPassword')
                  : _vm.$t('new Password'),"prop":"passwordConfirmation","rules":[
                _vm.errors.required,
                { validator: _vm.validatePassword, trigger: 'blur' },
              ]}},[_c('ods-input',{attrs:{"type":"password"},model:{value:(_vm.form.passwordConfirmation),callback:function ($$v) {_vm.$set(_vm.form, "passwordConfirmation", $$v)},expression:"form.passwordConfirmation"}})],1)],1)],1),_c('form-buttons',{attrs:{"cancel-to":"Profile"},on:{"submit":_vm.submitForm}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }